import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'gatsby'
import Layout from 'components/layout'
import Content from 'components/new/content'
import H2 from 'components/new/typography/h2'
import H3 from 'components/new/typography/h3'
import P from 'components/new/typography/p'
import Ul from 'components/new/ul'
import Spacer from 'components/new/spacer'
import { OutboundLink } from 'gatsby-plugin-google-gtag'

const CalendarContestRules = () => {
  return (
    <Layout kind='white'>
      <Helmet>
        <title>2024 Calendar Contest Rules | Hutson Inc</title>
      </Helmet>

      <Content kind='full'>
        <H2 as='h1'>2024 Calendar Contest Rules</H2>
        <P>
          No purchase is necessary to enter or win. A purchase does not increase the chances of
          winning.
        </P>
        <Spacer size='m' />
        <H3 as='h2'>Eligibility</H3>
        <P>
          This Contest is open only to those who reside or work in Hutson's Area of Responsibility,
          are not employeed by Hutson, Inc and are at least 18 years of age.
        </P>
        <Spacer size='m' />
        <H3 as='h2'>Agreement to Rules</H3>
        <P>
          By participating, the Contestant (“You”) agree to be fully unconditionally bound by these
          Rules, and You represent and warrant that You meet the eligibility requirements. In
          addition, You agree to accept the decisions of Hutson, Inc as final and binding as it
          relates to the content of this Contest.
        </P>
        <Spacer size='m' />
        <H3 as='h2'>Contest Period</H3>
        <P>
          Entries will be accepted online starting January 1, 2023 and ending September 30, 2023.
          All online entries must be received by 12:00:00 AM EDT on October 1, 2023.
        </P>
        <Spacer size='m' />
        <H3 as='h2'>How to Enter</H3>
        <P>
          The Contest must be entered by submitting an entry using{' '}
          <OutboundLink href='https://form.jotform.com/211474123572046'>
            the online form provided here
          </OutboundLink>
          . The entry must fulfill all Contest requirements, as specified, to be eligible to win a
          prize. Entries that are incomplete or do not adhere to the rules or specifications may be
          disqualified at the sole discretion of Hutson, Inc. Multiple entries are allowed.
        </P>
        <Spacer size='m' />
        <H3 as='h2'>Prizes</H3>
        <P>The Winner(s) of the Contest (the “Winner”) will receive:</P>
        <Ul>
          <li>$500 store credit for 1st place</li>
          <li>$250 store credit for 2nd place</li>
          <li>$100 store credit for 3rd place</li>
        </Ul>
        <P>
          Store credit may be used for parts, service, or STIHL equipment at any of{' '}
          <Link to='/locations/'>our locations</Link>. The specifics of the prize shall be solely
          determined by Hutson, Inc. No cash or other prize substitution shall be permitted except
          at Hutson, Inc's discretion.
        </P>
        <P>
          The prize is nontransferable. Any and all prize-related expenses, including without
          limitation any and all federal, state, and/or local taxes, shall be the sole
          responsibility of Winner. No substitution of prize or transfer/assignment of prize to
          others or request for the cash equivalent by Winner is permitted. Acceptance of prize
          constitutes permission for Hutson, Inc to use Winner’s name, likeness, and entry for
          purposes of advertising and trade without further compensation, unless prohibited by law.
        </P>
        <Spacer size='m' />
        <H3 as='h2'>Winner Selection and Notification</H3>
        <P>
          Members of Hutson, Inc's marketing team will review photos beginning October 2, 2023 and
          notify Winners with 30 days of the selection. Hutson, Inc shall have no liability for
          Winner’s failure to receive notices due to spam, junk e-mail, or other security settings
          or for Winner’s provision of incorrect or otherwise non-functioning contact information.
          If Winner cannot be contacted, is ineligible, fails to claim the prize within 30 days from
          the time award notification was sent, or fails to timely return a completed and executed
          declaration and release as required, the prize may be forfeited and an alternate Winner
          selected. Receipt by Winner of the prize offered in this Contest is conditioned upon
          compliance with any and all federal, state, and local laws and regulations.
        </P>
        <P>
          Any violation of these official rules by Winner (at Hutson, Inc's sole discretion) will
          result in Winner's disqualification as Winner of the Contest, and all privileges as Winner
          will be immediately terminated.
        </P>
        <Spacer size='m' />
        <H3 as='h2'>Rights Granted by You</H3>
        <P>
          By entering this content, You understand and agree that Hutson, Inc, anyone acting on
          behalf of Hutson, Inc, and Hutson, Inc licensees, successors, and assigns, shall have the
          right, where permitted by law, to print, publish, broadcast, distribute, and use in any
          media now known or hereafter developed, in perpetuity and throughout the World, without
          limitation, your entry, name, portrait, picture, voice, likeness, image, statements about
          the Contest, and biographical information for news, publicity, information, trade,
          advertising, public relations, and promotional purposes. without any further compensation,
          notice, review, or consent.
        </P>
        <P>
          By entering this content, You represent and warrant that your entry is an original work of
          authorship, and does not violate any third party’s proprietary or intellectual property
          rights. If your entry infringes upon the intellectual property right of another, You will
          be disqualified at the sole discretion of Hutson, Inc. If the content of your entry is
          claimed to constitute infringement of any proprietary or intellectual proprietary rights
          of any third party, You shall, at your sole expense, defend or settle against such claims.
          You shall indemnify, defend, and hold harmless Hutson, Inc from and against any suit,
          proceeding, claims, liability, loss, damage, costs or expense, which Hutson, Inc may
          incur, suffer, or be required to pay arising out of such infringement or suspected
          infringement of any third party’s right.
        </P>
        <Spacer size='m' />
        <H3 as='h2'>Terms &amp; Conditions</H3>
        <P>
          Hutson, Inc reserves the right, in its sole discretion, to cancel, terminate, modify or
          suspend the Contest should virus, bug, non-authorized human intervention, fraud, or other
          cause beyond Hutson, Inc's control corrupt or affect the administration, security,
          fairness, or proper conduct of the Contest. In such case, Hutson, Inc may select the
          Winner from all eligible entries received prior to and/or after (if appropriate) the
          action taken by Hutson, Inc. Hutson, Inc reserves the right, in its sole discretion, to
          disqualify any individual who tampers or attempts to tamper with the entry process or the
          operation of the Contest or website or violates these Terms &amp; Conditions. Hutson, Inc
          has the right, in its sole discretion, to maintain the integrity of the Contest, to void
          entries for any reason. Any attempt by an entrant to deliberately damage any website or
          undermine the legitimate operation of the Contest may be a violation of criminal and civil
          laws. Should such attempt be made, Hutson, Inc reserves the right to seek damages to the
          fullest extent permitted by law.
        </P>
        <Spacer size='m' />
        <H3 as='h2'>Limitation of Liability</H3>
        <P>
          By entering, You agree to release and hold harmless Hutson, Inc and its subsidiaries,
          affiliates, advertising and promotion agencies, partners, representatives, agents,
          successors, assigns, employees, officers, and directors from any liability, illness,
          injury, death, loss, litigation, claim, or damage that may occur, directly or indirectly,
          whether caused by negligence or not, from:
        </P>
        <Ul>
          <li>
            such entrant’s participation in the Contest and/or his/her acceptance, possession, use,
            or misuse of any prize or any portion thereof;
          </li>
          <li>
            technical failures of any kind, including but not limited to the malfunction of any
            computer, cable, network, hardware, or software, or other mechanical equipment;
          </li>
          <li>
            the unavailability or inaccessibility of any transmissions, telephone, or Internet
            service;
          </li>
          <li>
            unauthorized human intervention in any part of the entry process or the Promotion;
          </li>
          <li>
            electronic or human error in the administration of the Promotion or the processing of
            entries.
          </li>
        </Ul>
        <Spacer size='m' />
        <H3 as='h2'>Disputes</H3>
        <P>
          This Contest is governed by the laws of states that Hutson operates in and the United
          States of America without respect to conflict of law doctrines. As a condition of
          participating in this Contest, participant agrees that any and all disputes that cannot be
          resolved between the parties, and causes of action arising out of or connected with this
          Contest, shall be resolved individually, without resort to any form of class action,
          exclusively before a court. Further, in any such dispute, under no circumstances shall
          participant be permitted to obtain awards for, and hereby waives all rights to, punitive,
          incidental, or consequential damages, including reasonable attorney’s fees, other than
          participant’s actual out-of-pocket expenses (i.e. costs associated with entering this
          Contest). Participant further waives all rights to have damages multiplied or increased.
        </P>
        <Spacer size='m' />
        <H3 as='h2'>Privacy Policy</H3>
        <P>
          Information submitted with an entry is subject to{' '}
          <Link to='/privacy-policy/'>the Privacy Policy stated on our website</Link>.
        </P>
        <P>
          If you have any questions about these rules, please feel free to email us at{' '}
          <a href='mailto:marketing@hutsoninc.com'>marketing@hutsoninc.com</a>.
        </P>
      </Content>
    </Layout>
  )
}

export default CalendarContestRules
